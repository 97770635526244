import React from "react";

export default function Counter() {
    return (
        <div className="flex flex-col justify-center items-center">
                <h2>
                    <span className="flex justify-center tabular-nums md:ml-0 ml-4 text-texto-400 md:text-5xl text-3xl font-extrabold mb-2 animate-[counter_2s_ease-out_forwards] [counter-set:_num_var(--num)] before:content-[counter(num)]">
                        <span className="sr-only">2000</span>+
                    </span>
                </h2>
                <div className="sm:text-xl w-max text-center text-nowrap font-semibold bg-clip-text text-texto-400"><span className="text-desinfopedia font-semibold">Notícias falsas</span> coletadas de diferentes <br/>checadores de fato por nosso algoritmo</div>
        </div>
    );
}
import Home from "./Home"
import Acervo from "./Acervo";
import About from "./About";
import TrendingFake from "./TrendingFake";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { Analytics } from "@vercel/analytics/react"

export default function App() {
    return (
    <Router>
        <div>
            <Routes>
                <Route index element={<Home></Home>}></Route>
                <Route path="/acervo" element={<Acervo></Acervo>}></Route>
                <Route path="/trending" element={<TrendingFake></TrendingFake>}></Route>
                <Route path="/sobre" element={<About></About>}></Route>
            </Routes>
        </div>
    </Router>
    
    
    );
}
import React from "react"
import Hero from "./SubModules/Hero.jsx"
import Header from "./SubModules/Header.jsx"

export default function Home() {
    return(
        <div>
            <Header></Header>
            <Hero></Hero>
        </div>
    );
}
import React from "react";
import Header from "./SubModules/Header.jsx";
import CardImagem from "./SubModules/CardImagem.jsx";
import QuemSomos from "./images/quem_somos.webp"
import FotoTime from "./images/foto_time.webp"
import FotoIsef from "./images/foto-isef.png"
import FotoIsef2 from "./images/foto-isef-dois.png"
import FotoFolha from "./images/foto-folha.png"


export default function About() {
    return (
    <div className="background-sobre">
        <Header></Header>
        <div className="flex flex-col justify-center content-center mt-8 lg:px-36 lg:mx-2 px-6">

            <h1 className="text-texto-400 self-center text-3xl text-center font-bold">Quem somos?:</h1>
            <h2 className="text-texto-400 self-center text-2xl text-center mt-4">A Desinfopedia contra a desinformação</h2>

            <div className="max-w rounded overflow-hidden shadow-lg bg-white mt-6 self-center">
                <img className="w-full h-full" src={QuemSomos} alt="Logo checador"/>
            </div>
            <p className="text-texto-400 self-center mt-6 italic">Criado por Pietro Andrade Quinzani e João Pedro Sassi Sandre sob a orientação do professor doutor Francisco Tupy.</p>

            <h2 className="text-texto-400 self-start text-3xl mt-12 font-bold">Nossa história:</h2>
            <p className="text-texto-400 mt-6 text-left pl-6 border-l-4 text-xl border-texto-400 italic">
                “A base surgiu a partir da realização de um estudo das fake news divulgadas no Brasil. Foi aí que ficou claro pra nós a precariedade e escassez de 
                dados no tema. Daí surgiu a necessidade de expandir as informações disponíveis e transforma-las em um 
                repositório online, atualizado automaticamente. O objetivo virou promover uma plataforma tanto para a 
                checagem de notícias de forma prática e rápida, quanto para a entrega de dados robustos 
                e seguros para pesquisadores, como nós.”
                <br></br>
                <span className="text-texto-400 text-left font-bold text-xl pt-4 inline-block">- João Pedro Sassi Sandre (co-fundador da Desinfopedia)</span>
            </p>

            <p className="text-texto-400 mt-6 text-left text-lg mb-4">
                Criada por dois jovens cientistas brasileiros cursando o ensino médio, a base foi montada para a realização de um 
                estudo acerca das fake news no país. Tendo sucesso com a pesquisa e compilação das informações, os dados foram 
                expandidos e convertidos em um repositório online.
            </p>
            <p className="text-texto-400 text-left text-lg mb-4">
                Os jovens, João Pedro Sassi Sandre e Pietro Andrade Quinzani, orientados por Francisco Tupy iniciaram o desenvolvimento 
                do projeto em Abril de 2022, levando a Desinfopedia para a FEBRACE (Feira Brasileira de Ciências e Engenharia) em 2023 e 
                a Mostra Tech, no mesmo ano.
            </p>
            <p className="text-texto-400 text-left text-lg mb-4">
                Os estudantes, atualmente, no ensino médio, levam, em conjunto com pesquisas passadas, dois títulos FEBRACE em 
                Ciências Sociais, como <a href="https://virtual.febrace.org.br/2022/SOC/298/" target="blank">vice-campeões</a> e <a href="https://virtual.febrace.org.br/2023/SOC/1845/" target="blank">campeões</a>, consecutivamente. Além disso, atenderam a 
                Mostra Nacional 2022, em Brasília, e a renomada feira internacional <a href="https://www.societyforscience.org/press-release/regeneron-isef-full-awards-2022/" target="blank">ISEF</a>, em Atlanta, levando o quarto 
                lugar em sua categoria.
            </p>

            <h2 className="self-start text-3xl mt-12 font-bold text-texto-400">Donwload da base em Excel:</h2>
            <p className="text-texto-400 text-left text-lg mt-6 mb-4">
                Para baixar em excel a base Desinfopedia de <i>fake news</i> basta accessar a nossa página no <span className="text-desinfopedia"><a href="https://github.com/PietroQuin/Desinfopedia" target="blank">GitHub!</a></span> (A atualização do Excel esta temporiamente pausada)
            </p>

            <p className="text-texto-400 text-left text-lg mb-4">
                Todos os dados da base são de livre acesso para uso completo, compartilhamento e remixagem sob a licença <a className="text-desinfopedia hover:text-[#CD0306]" href="https://creativecommons.org/licenses/by/4.0/" target="blank">Creative Commons Atribuição 4.0 Internacional.</a> O nome “Desinfopedia” se encontra sob processo de patenteamento junto ao INPI desde 28 de fevereiro de 2023 com o número de pedido 929598334.
            </p>

            <p className="text-texto-400 text-left text-lg mb-4"><span className="font-bold">Materiais <span className="text-desinfopedia">Desinfopedia</span> de educação midiática:</span> <a href="https://linktr.ee/Desinfopedia" target="blank">https://linktr.ee/Desinfopedia</a></p>

            <p className="text-texto-400 text-left text-lg mb-4">
                <span className="font-bold">Email:</span> desinfopedia@gmail.com
            </p>
            
        
            <div className="">

                <h2 className="text-texto-400 text-3xl self-start mt-6 font-bold">Galeria:</h2>

                <div className="2xl:grid-cols-3 xl:grid-cols-2 xl:grid-rows-3 grid-cols-1 grid-rows-6 2xl:grid-rows-2 grid mx-auto py-2">
                    <CardImagem texto="Com o prêmio da feira brasileira de ciências e engenharia em mãos." image={FotoTime}></CardImagem>
                    <CardImagem texto="Após a premiação na feira internacional de ciências e engenharia em Atlanta, nos EUA." image={FotoIsef}></CardImagem>
                    <CardImagem texto="Apresentado na feira internacional de ciências e engenharia em Atlanta no EUA." image={FotoIsef2}></CardImagem>
                    <CardImagem texto="Matéria da Folha de São Paulo sobre a Desinfopedia e seu uso." image={FotoFolha}></CardImagem>
                </div>

            </div>
            
        </div>
    </div>
    );
}


import React from "react"
import Counter from "./Counter";
import image from "../images/hero-dd-texto.png"

export default function Hero() {
    return(
        <div className=" flex flex-row md:my-20 my-10 justify-center items-center ">
            <div className="flex flex-col justify-center justify-items-center items-center">
                <img src={image} alt="Logo Desinfopedia" className="self-center justify-self-center mb-8 md:w-max w-5/6"></img>
                <div className="flex flex-row mt-8 mb-12 mx-6 h-auto align-middle justify-center">
                    <a href="/acervo">
                        <div className="flex flex-row justify-center px-4 align-middle bg-[#B1040C] shadow w-max h-12 rounded-full ease-in-out duration-300 hover:scale-105"><button className="text-texto-400 sm:text-xl font-bold">Acesse nosso acervo de checagens!</button></div>
                    </a>
                </div>
                <Counter></Counter>
            </div>   
        </div>
    );
}
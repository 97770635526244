import React from "react";
import Header from "./SubModules/Header.jsx";
import GalleryItem from "./SubModules/GalleryItem.jsx";

var mapNoticia =  function mapMongo(details) {


        /*Maping of the props itself*/
        return (
        <GalleryItem
            key = {details.Link} 
            data={(details.Data).slice(0,10)}
            texto={details.Título}
            link={details.Link}
            checador={details.Fonte}
        />);

    }

export default function Acervo() {


    /*Backendata, loading and error handling variables*/
    const [backendData, setBackendData] = React.useState(null);
    const [isPending, setIsPending] = React.useState(true);
    const [isError, setIsError] = React.useState(false);
    const [zeroHits, setHits] = React.useState(false)

    /*Search Method and API request variables*/
    var [searchMethod, setMethod] = React.useState("/noticias/")
    var [searchQuery, setQuery] = React.useState(false)


    /*Page control*/
    var [pag, setPag] = React.useState(1)
    function nextPage() {
        setPag(pag+1)
    }

    /*TODO: Criar Handler do request de search na API*/
    function changeSearch(event) {
        if (event.target.value !== "") {
            setMethod("/search/")
            setQuery(event.target.value)
            setPag(1)
        }
        else {
            setMethod("/noticias/")
            setQuery(false)
        }
    }

    function handleEnter(event) {
        if (event.key === "Enter") {
            changeSearch()
        }
    }

    /*API data fetching*/
    React.useEffect(() => {
        fetch("https://desinfopediaapi.pythonanywhere.com/" + searchMethod + (searchQuery ? ("/" + pag + "?search=" + searchQuery) : pag)).then(
        response => response.json()
        ).then(
            data => {
                setIsPending(false)
                if (data["hits"]) {
                    if (data["estimatedTotalHits"] !== 0) {
                        setBackendData(data["hits"])
                        setHits(false)
                    }
                    else {
                        setBackendData(false)
                        setHits(true)
                    }
                }
                else {
                    setHits(false)
                    setBackendData(data)
                }
                
            }
        ).catch(err => {
            setIsError(true)
            setBackendData(false)
            setIsPending(false)
        }) 
    }, [pag, searchQuery, searchMethod])


    return(
        <div className="background-acervo">

            <Header></Header>
            
            <div className="flex flex-col flex-1 justify-center items-center">
                <h1 className="pt-12 text-center text-wrap mx-20 text-texto-400 text-3xl font-bold">{isPending ?  "CARREGANDO NOTÍCIAS..." : "CHECAGENS MAIS RECENTES"}</h1>
                {isError && <h2 className="pt-12 text-wrap mx-10 text-center text-texto-400 sm:text-3xl text-xl font-bold">Não foi possível acessar o servidor. Tente novamente mais tarde</h2>}
                <h2 className="pt-12 text-center text-texto-400 text-3xl font-bold">{zeroHits ? "Nenhum resultado encontrado para sua busca:" : ""}</h2>
                
                {(!isPending && !isError) && 
                <div className="flex items-center sm:w-3/5 w-5/6 h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden hover:bg-texto-800 duration-200">
                    <div className="grid place-items-center h-full w-12 text-gray-300">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </div>
                
                    <input
                        onInput={changeSearch}
                        className="peer h-full w-full outline-none text-sm text-gray-700 pr-2  hover:bg-texto-800 hover:placeholder-texto duration-200"
                        type="text"
                        id="search"
                        name="search"
                        placeholder="Procure por uma notícia falsa..." /> 
                                    
                </div>}
            </div>

            {backendData && 
            <div>
                <div className="container mx-auto px-5 py-2 md:px-32 md:pt-12">
                    <div className="-m-0 flex flex-wrap md:-m-2 justify-center">
                        {backendData && backendData.map(mapNoticia)}
                    </div>
                </div>
                
                <div className="flex flex-row my-8 mx-6 h-auto align-middle justify-center">
                    <div onClick={nextPage} onKeyDown={handleEnter} className="flex flex-row justify-center align-middle bg-[#000000] shadow w-44 h-12 rounded-full ease-in-out duration-300 hover:scale-105"><button className="text-texto-400 text-xl font-bold" onClick={nextPage}>Ver mais</button></div>
                </div>
                
            </div>
            }
            
        </div>
    );
}
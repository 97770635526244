import React from "react";
import  FirstCard from "../images/first-card.png"
import SecondCard from "../images/second-card.png"
import ThirdCard from "../images/third-card.png"
import FourthCard from "../images/fourth-card.png"
import FifthCard from "../images/fifth-card.png"


export default function CardTrending(props) {

    var imageCard = null
    if (props.Ordem === "1") {
        imageCard = FirstCard
    }
    else if (props.Ordem === "2") {
        imageCard = SecondCard
    }
    else if (props.Ordem === "3") {
        imageCard = ThirdCard
    }
    else if (props.Ordem === "4") {
        imageCard = FourthCard
    }
    else {
        imageCard = FifthCard
    }

    return(
        <div className="bg-white rounded border-2 border-desinfopedia overflow-hidden shadow-lg my-4 mx-6 sm:w-3/4 w-full grid grid-cols-3 grid-rows-1">
            <div className="col-span-2 mx-4 flex flex-col justify-center items-start">
                <h2 className="text-desinfopedia font-bold xl:text-8xl md:text-6xl text-4xl truncate text-wrap xl:tracking-widest sm:tracking-wide tracking-normal sick-font">{props.Entidade}</h2>
                <h2 className="text-slate-900 font-bold md:text-sm text-xs tracking-widest my-px xl:mx-2">Numero de Aparições essa semana: {props.Aparições}</h2>
            </div>
            <img className="w-max" src={imageCard} alt="Medidas da Desinfopedia contra a Desinformação"/>
        </div>
    );
}


import React from "react";
import Card from "./Card";

export default function GalleryItem(props) {

    return(
        <div className="flex lg:w-1/2 xl:w-1/3 flex-wrap">
            <div className="w-full p-1 md:p-6">
                <Card data={props.data} checador={props.checador} link={props.link} titulo={props.titulo} texto={props.texto}></Card>
            </div>
        </div>
    );
    
}
import React from "react";
import Header from "./SubModules/Header";
import CardTrending from "./SubModules/CardTrending";

export default function TrendingFake() {

    const [dt, setDt] = React.useState(new Date());
    const [backendData, setBackendData] = React.useState(null);
    const [isPending, setIsPending] = React.useState(true);
    const [isError, setIsError] = React.useState(false);

    function PopulateTrends(data) {
        return(
            <CardTrending Entidade={data.Entidade} Aparições={data.Aparições} Ordem={data.Ordem}>
            </CardTrending>
        );
    }

    React.useEffect(() => {
        let secTimer = setInterval( () => {
        setDt(new Date())
        },1000)

        return () => clearInterval(secTimer);
    }, []);

    React.useEffect(() => {
        fetch("https://desinfopediaapi.pythonanywhere.com/trending").then(
            response => response.json()
        ).then(
            data => {
                setIsPending(false)
                setBackendData(data["hits"])
            },
        ).catch(err => {
            setIsError(true)
            setBackendData(false)
            setIsPending(false)
        }) 
    }, [])

    return(
        <div>
            <Header></Header>
            <div className="flex flex-col justify-center items-center lg:px-36 lg:py-12 px-10 py-4">
                <p className="text-texto-400 counter-timer tracking-widest font-bold pb-4">Tempo até atualização:</p>
                <h1 className="sick-font lg:text-9xl text-6xl sm:text-8xl text-desinfopedia tracking-widest font-bold">{17 - dt.getHours() < 10 && 17 - dt.getHours() >= 0 ? 0 : null}{17 - dt.getHours() >= 0 ? 17 - dt.getHours() : -(24 - 17 - dt.getHours())}:{60 - dt.getMinutes() < 10 && 0}{60 - dt.getMinutes()}:{60 - dt.getSeconds() < 10 && 0}{60 - dt.getSeconds()}</h1>
                
                {isPending && <h2 className="pt-12 px-36 mx-2 text-texto-400 text-3xl font-bold">Carregando...</h2>}
                {isError && <h2 className="pt-12 sm:px-36 mx-12 text-texto-400 sm:text-3xl text-xl font-bold text-center">Não foi possível acessar o servidor. tente novamente mais tarde</h2>}
                
                
                {!isError && <p className="text-texto-400 counter-timer text-center tracking-widest font-bold pt-4 pb-8">Os tópicos em alta nas <span className="italic">fake news</span> brasileiras:</p>}
                {backendData && backendData.map(PopulateTrends)}
                
            </div>
        </div>
    );
}
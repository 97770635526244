import React from "react";


export default function CardImagem(props) {
    return(
        <div>
            <div className="mx-6 my-6 ease-in-out duration-300 rounded overflow-hidden shadow-lg bg-white hover:scale-105 flex flex-col">

                <img className="w-full" src={props.image} alt="Medidas da Desinfopedia contra a Desinformação"/>
                
                <div className="px-6 pt-2 pb-2">
                    <p className="text-neutral-950 text-bold text-base text-left">{props.texto}</p>
                </div>
                
            </div>
        </div>
    );
}
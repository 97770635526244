import React from "react"
import ItemHeader from "./ItemHeader";
import logo from "../images/logo_png-removebg-preview.svg"

export default function Header() {

    return(
        <div>
            <header>
                <div className="flex flex-row pt-6 justify-center space-x-6 md:space-x-8 sm:ml-4 content-center">
                    <img src={logo} alt="Logo Desinfopedia" className="size-16 justify-self-start mt-2 align-self-center sm:block hidden"></img>
                    <ItemHeader texto="Início" route="/"/>
                    <ItemHeader texto="Checagens" route="/acervo"/>
                    <ItemHeader texto="Trending Fake"  route="/trending"/>
                    <ItemHeader texto="Sobre" route="/sobre"/>
                </div>
                <div className="flex flex-row pt-2 justify-center items-center space-x-4 content-center transition1:hidden">
                    <img src={logo} alt="Logo Desinfopedia" className="size-16 justify-self-start mt-2 align-self-center sm:block md:hidden"></img>
                </div>
            </header>
        </div>
    );
}